<template>
  <div>
    <HeadBanner page-type="home" />
    <div class="c-homePage">
      <MzhcHeroArea
        v-if="privateAccountInfo.isMzhc"
        class="c-contentHeader"
      />
      <HomeHeroArea
        v-else
        class="c-contentHeader"
        :hero-areas-list="heroArea"
      />
      <div class="c-columns">
        <HomeMainContent
          :home-content="homeContent"
          :my-service-histories="myServiceHistories"
          :my-favorite-services="myFavoriteServices"
          :my-recommend-requests="myRecommendRequests"
          class="c-columns_main"
        />
        <HomeAsideContent class="c-columns_side" />
      </div>
      <div
        v-if="$translate.showJapaneseOnly()"
        class="c-footer"
      >
        <HomeFooterContent :news="homeContent.newsList" />
      </div>
    </div>
    <ClientOnly>
      <GlobalPopup
        v-if="isLoggedIn"
        :is-shown="isActiveGlobalPopup"
        @completed-get-popup="onCompletedGetGlobalPopup"
      />
      <UserAttributesSelectModalWrapper
        v-if="showUserAttributesSelectModalWrapper"
        @close="showUserAttributesSelectModalWrapper = false"
      />
      <DRegisterCompleteModal
        v-if="isLoggedIn && isShownRegisterCompleteModal"
        :active="isActiveRegisterCompleteModal"
        :register-complete-type="$route.query.register_complete_type"
        ref="registerCompleteModal"
        @close="closeRegisterCompleteModal"
      />
      <DAttributePrivateToCorporateModal
        v-if="isShownAttributePrivateToCorporateModal"
        :active="activeAttributePrivateToCorporateModal"
        ref="attributePrivateToCorporateModal"
        @close="activeAttributePrivateToCorporateModal = false"
      />
      <BottomFixedBar v-if="isPC && !isLoggedIn" />
    </ClientOnly>
    <DLoading
      :active="$fetchState.pending"
      is-full-page
    />
    <TheVueport breakpoint-type="vueportHome" />
    <Portal to="pageTranslate">
      <PageTranslate v-if="!$fetchState.pending && $translate.isTranslatableTarget()" />
    </Portal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import HeadBanner from '~/components/organisms/HeadBanner'
import HomeAsideContent from '~/components/organisms/HomeAsideContent'
import HomeFooterContent from '~/components/organisms/HomeFooterContent'
import MzhcHeroArea from '~/components/organisms/MzhcHeroArea'
import HomeHeroArea from '~/components/organisms/HomeHeroArea'
import HomeMainContent from '~/components/organisms/HomeMainContent'
import DLoading from '~/components/atoms/DS2/DLoading'
import TheVueport from '~/components/organisms/TheVueport'
import { REGISTER_COMPLETE_TYPE } from '~/components/organisms/DS2/DRegisterCompleteModal/DRegisterCompleteModal'
import zendeskChatMixin from '~/mixins/zendesk-chat/private-mixin'
import {
  ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_KEY,
  ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_VALUE
} from '~/pages/update_registration_info/buyer/corporate.vue'
import { HEADER_TYPE_COOKIE } from '~/constants/cookie'
import HomeAccessor from '~/api/accessors/home/home-accessor'

const TITLE = 'ココナラ - プロが集まる日本最大級のスキルマーケット'
const DESCRIPTION =
  'ココナラは、いろんなジャンルの「仕事」や「相談」をネットショッピングのように早く・簡単・おトクに依頼できます！ロゴや名刺などのデザイン、動画・サイト制作、お悩み相談など、ビジネスやプライベートで自分ではできないことをプロや専門家に依頼しませんか？No.1スキルマーケットのココナラ！'
const KEYWORDS = 'スキルマーケット,スキルシェア,マーケットプレイス,ココナラ,coconala,ここなら'
const RECENTLY_VIEWED_COOKIE_KEY_V2 = 'service_history'
const RECENTLY_VIEWED_BUSINESS_COOKIE_KEY = 'service_history_business'
const RECENTLY_VIEWED_SERVICES_EXPIRE_DAY = 30

export default {
  name: 'PrivateIndex',
  mixins: [zendeskChatMixin],
  components: {
    DLoading,
    HeadBanner,
    HomeAsideContent,
    HomeFooterContent,
    MzhcHeroArea,
    HomeHeroArea,
    HomeMainContent,
    TheVueport,
    GlobalPopup: () => import('~/components/organisms/GlobalPopup'),
    PageTranslate: () => import('~/components/organisms/PageTranslate'),
    UserAttributesSelectModalWrapper: () =>
      import('~/components/organisms/UserAttributesSelectModalWrapper'),
    DRegisterCompleteModal: () => import('~/components/organisms/DS2/DRegisterCompleteModal'),
    DAttributePrivateToCorporateModal: () =>
      import('~/components/organisms/DS2/DAttributePrivateToCorporateModal'),
    BottomFixedBar: () => import('~/components/organisms/BottomFixedBar')
  },
  head() {
    return this.head
  },
  data() {
    return {
      homeContent: {
        categoryRanking: {
          detailsList: [],
          rankingTermEnd: null,
          rankingTermStart: null
        },
        heroAreasList: [],
        heroAreasProviderList: [],
        heroAreasBuyerList: [],
        heroAreasNoLoggedInList: [],
        newsList: [],
        recommendBlogsList: [],
        recommendRequestsList: [],
        recommendServicesList: [],
        recommendUsersList: [],
        recommendCategoriesList: [],
        useScenesList: [],
        legalQasList: []
      },
      myServiceHistories: [],
      myFavoriteServices: [],
      myRecommendRequests: {
        algorithm: '',
        impressionParamsJson: '',
        recommendRequestsList: [],
        currentDate: undefined
      },
      myRecommendImpressionParamsJson: '',
      isLoading: false,
      linkSpecs: {
        /** 人気のカテゴリ見出し部 */
        popularCategories: {
          link: '/services',
          text: 'すべてのカテゴリをみる',
          isNuxtLink: true
        }
      },
      // 登録完了ポップアップの表示条件
      isShownRegisterCompleteModal: Object.values(REGISTER_COMPLETE_TYPE).includes(
        this.$route.query.register_complete_type
      ),
      // 登録完了ポップアップの開閉フラグ（アニメーション表示の為に表示条件とは別にする）
      isActiveRegisterCompleteModal: false,
      isActiveGlobalPopup: false,
      isShownAttributePrivateToCorporateModal: false,
      activeAttributePrivateToCorporateModal: false,
      showUserAttributesSelectModalWrapper: false
    }
  },
  async fetch() {
    const { fullPath } = this.$route
    // TODO: api実装後につなぎこみ
    // ブログ検索でSSRとCSRの二重リクエストが発生したが再現しなかったので制御は入れず様子見する
    const promises = [
      new Promise((resolve, reject) => {
        this.$store
          .dispatch('pages/home/home/homeContentRequests', fullPath)
          .then(resolve)
          .catch(reject)
      })
    ]
    // 閲覧履歴とお気に入りは通信でエラーが発生しても握りつぶしている（表示しない)
    if (this.$store.getters['auth/isLoggedIn']) {
      promises.push(this.$store.dispatch('pages/home/home/myFavoriteServicesRequests'))
      // ビジアカ統合に伴い旧ビジネストップの閲覧データを保持している場合は、
      // 一回だけ、RECENTLY_VIEWED_COOKIE_KEY_V2にコピーして破棄します。
      const businessCookieValue = this.$cookies.get(RECENTLY_VIEWED_BUSINESS_COOKIE_KEY)
      if (businessCookieValue) {
        const expires = this.$util.dayjs().add(RECENTLY_VIEWED_SERVICES_EXPIRE_DAY, 'days').toDate()
        const cookieOption = {
          path: '/',
          expires
        }
        this.$cookies.set(RECENTLY_VIEWED_COOKIE_KEY_V2, businessCookieValue, cookieOption)
        this.$cookies.remove(RECENTLY_VIEWED_BUSINESS_COOKIE_KEY)
        promises.push(
          this.$store.dispatch('pages/home/home/myServiceHistoriesRequests', businessCookieValue)
        )
      } else {
        const cookie = this.$cookies.get(RECENTLY_VIEWED_COOKIE_KEY_V2) || ''
        if (cookie) {
          promises.push(this.$store.dispatch('pages/home/home/myServiceHistoriesRequests', cookie))
        } else {
          promises.push(Promise.resolve(null))
        }
      }
      if (this.isDisplayProvider) {
        this.getMyRecommendRequests()
      }
    }
    try {
      const [res1, res2, res3] = await Promise.all(promises)
      this.homeContent = res1
      if (res2) {
        this.myFavoriteServices = res2.servicesList
      }
      if (res3) {
        this.myServiceHistories = res3.servicesList
      }
    } catch (err) {
      const statusCode = this.$util.grpcCodeToHttpStatusCode(Number(err.grpcCode))
      return this.$nuxt.context.error({
        statusCode,
        message: '',
        grpcCode: err.grpcCode
      })
    }
  },
  async mounted() {
    this.setDataLayer()
    if (this.isShownRegisterCompleteModal) {
      // モーダル表示のアニメーションの為にDOM取得を待つ
      await this.$util.waitUntil(() => !!this.$refs.registerCompleteModal)
      this.isActiveRegisterCompleteModal = true
    } else {
      this.isActiveGlobalPopup = true
    }
    const attributePrivateToCorporateModalLocalStorageValue = localStorage.getItem(
      ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_KEY
    )
    this.isShownAttributePrivateToCorporateModal =
      attributePrivateToCorporateModalLocalStorageValue ===
      ATTRIBUTE_PRIVATE_TO_CORPORATE_MODAL_LOCAL_STORAGE_VALUE
    if (this.isShownAttributePrivateToCorporateModal) {
      await this.$util.waitUntil(() => !!this.$refs.attributePrivateToCorporateModal)
      this.activeAttributePrivateToCorporateModal = true
    }
  },
  computed: {
    ...mapState('pages/users', ['user']),
    ...mapGetters('ui', ['isPC', 'isTB', 'isSP']),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('my/header', ['privateAccountInfo', 'isProvider', 'headerType']),
    ...mapState('constants', ['HEADER_TYPE']),
    head() {
      return {
        __dangerouslyDisableSanitizers: ['script'],
        link: [{ rel: 'canonical', href: 'https://coconala.com/' }],
        title: TITLE,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: DESCRIPTION
          },
          {
            hid: 'Keywords',
            name: 'Keywords',
            content: KEYWORDS
          },
          ...this.metaOgp
        ]
      }
    },
    scrollToOffset() {
      if (this.isSP) {
        return -19
      } else if (this.isTB) {
        return 0
      } else {
        return -90
      }
    },
    metaOgp() {
      return [
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://coconala.com/'
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: TITLE
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'ja_JP'
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'ココナラ'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: DESCRIPTION
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'http://coconala.com/images/facebook.png'
        }
      ]
    },
    heroArea() {
      if (this.isLoggedIn) {
        const headerType = this.$cookies.get('header_type')

        if (headerType) {
          // 受注発注の判定をheader_type(cookie)の値を見て判定を行う
          return headerType === HEADER_TYPE_COOKIE.VALUE_PROVIDER
            ? this.homeContent.heroAreasProviderList
            : this.homeContent.heroAreasBuyerList
        }
        // EF経由のユーザーでheader_typeのcookieが一部セットされないことがあるのでその場合はis_providerのフラグを見る
        return this.isProvider
          ? this.homeContent.heroAreasProviderList
          : this.homeContent.heroAreasBuyerList
      }
      return this.homeContent.heroAreasNoLoggedInList
    },
    isDisplayProvider() {
      return this.headerType === this.HEADER_TYPE.PROVIDER
    }
  },
  methods: {
    setDataLayer() {
      if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = []
      }
      window.dataLayer.push({
        pagetype: 'home'
      })
    },
    closeRegisterCompleteModal() {
      this.isActiveRegisterCompleteModal = false
      setTimeout(() => {
        this.isActiveGlobalPopup = true
      }, 350)
      const query = Object.fromEntries(
        Object.entries(this.$route.query).filter(([k]) => k !== 'register_complete_type')
      )
      this.$router.replace({ query })
    },
    onCompletedGetGlobalPopup(popupType) {
      if (!popupType) this.showUserAttributesSelectModalWrapper = true
    },
    getMyRecommendRequests() {
      const homeAccessor = new HomeAccessor(this.$store.state)
      homeAccessor
        .getMyRecommendRequests()
        .then(response => {
          this.myRecommendRequests = { ...response }
        })
        .catch(error => {
          this.$sentry.captureException(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.c-contentHeader {
  margin: 20px auto 32px;
  width: 1280px;
}

.c-homePage {
  position: relative;
  margin: 0 auto;
  max-width: 1280px;
}

.c-columns {
  display: flex;
  margin: 12px 20px 0;

  justify-content: space-between;
}

@media (min-width: breakpoint(Home, M) + 1) {
  .c-homePage {
    overflow-x: auto;

    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari 対応 */
    }
  }

  .c-columns {
    flex-direction: row-reverse;
    width: 1240px;

    &_main {
      flex: 0 0 964px;
      width: 964px;
    }

    &_side {
      flex: 0 0 256px;
      margin-right: 20px;
      width: 256px;
    }
  }

  .c-footer {
    margin: 0 20px;
    width: 1240px;
  }
}

@media (max-width: breakpoint(Home, M)) {
  .c-contentHeader {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .c-homePage {
    max-width: 100%;
    font-size: 14px;
  }

  .c-columns {
    flex-direction: column;
    margin: 0;
    &_side {
      margin-top: 45px;
    }
  }

  .c-footer {
    padding-bottom: 60px;
  }
}
</style>
