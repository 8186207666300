<template>
  <div class="c-homeMainContent">
    <CarouselRecommendRequests
      v-if="isLoggedIn && myRecommendRequests.recommendRequestsList.length"
      :recommend-requests="myRecommendRequests.recommendRequestsList"
      :impression-params-json="myRecommendRequests.impressionParamsJson"
      :current-date="myRecommendRequests.currentDate.seconds"
    />

    <CarouselService
      v-if="isLoggedIn && myFavoriteServices.length"
      title="お気に入り"
      title-tag="h2"
      title-url-more="お気に入りをもっと見る"
      url-more="/mypage/favorites"
      :services="myFavoriteServices"
      link-ref="top_favorites"
      link-ref-kind="home"
      class="c-homeMainContent_favoriteService"
      :class="{
        'c-homeMainContent_favoriteService-hasRecommendRequests':
          myRecommendRequests.recommendRequestsList.length
      }"
      data-translate
    />

    <CarouselService
      v-if="isLoggedIn && myServiceHistories.length"
      title="閲覧履歴"
      title-tag="h2"
      :services="myServiceHistories"
      link-ref="top_histories"
      link-ref-kind="home"
      class="c-homeMainContent_serviceHistory"
      data-translate
    />

    <!-- PC未ログイン時で言語設定が日本語のみ表示 -->
    <BeginnerBanner
      v-if="showBeginnerBanner"
      target-device="PC"
      class="c-homeMainContent_banners"
      page-type="home"
    />

    <RecommendCategories
      class="c-homeMainContent_recommendCategories"
      :class="{ 'c-homeMainContent_recommendCategories-mzhc': privateAccountInfo.isMzhc }"
      :recommend-categories-list="homeContent.recommendCategoriesList"
    />

    <!-- SP未ログイン時で言語設定が日本語のみ表示 -->
    <BeginnerBanner
      v-if="showBeginnerBanner"
      target-device="SP"
      class="c-homeMainContent_banners"
      page-type="home"
    />

    <PopularUseScenes
      v-if="$translate.showJapaneseOnly()"
      class="c-homeMainContent_useScenes"
    />

    <EzTopRegisterForFree
      v-if="!isLoggedIn"
      class="c-homeMainContent_register isSP"
      ref-value="sp_top_main_register_1"
      type="primary"
      data-translate
    />

    <CoconalaMerit
      class="c-homeMainContent_coconalaMerit"
      v-if="!isLoggedIn"
    />

    <template v-if="!privateAccountInfo.isMzhc">
      <CarouselService
        title="おすすめサービス"
        title-tag="h2"
        title-url-more="おすすめサービスをもっと見る"
        url-more="/search"
        :services="homeContent.recommendServicesList"
        link-ref="top_recommend_service"
        link-ref-kind="home"
        is-nuxt-link-headline
        class="c-homeMainContent_recommendService"
      />

      <CarouselUser
        title="おすすめユーザー"
        title-tag="h2"
        title-url-more="ユーザーをもっと見る"
        url-more="/users/"
        :users="homeContent.recommendUsersList"
        link-ref="top_recommend_user"
        link-ref-kind="home"
        class="c-homeMainContent_recommendUser"
        is-nuxt-link-headline
      />

      <CarouselBlog
        v-if="$translate.showJapaneseOnly()"
        title="人気の記事・コンテンツ"
        title-tag="h2"
        title-url-more="記事・コンテンツをもっと見る"
        title-url-more-sp="もっと見る"
        url-more="/blogs/"
        :blogs="homeContent.recommendBlogsList"
        link-ref="top_recommend_blog"
        link-ref-kind="home"
        class="c-homeMainContent_recommendBlog"
      />

      <CarouselRequest
        v-if="!isDisplayProvider && $translate.showJapaneseOnly()"
        title="募集中のお仕事"
        title-tag="h2"
        title-url-more="募集中のお仕事をもっと見る"
        title-url-more-sp="もっと見る"
        url-more="/requests/"
        :current-date="homeContent.currentDate"
        :requests="homeContent.recommendRequestsList"
        link-ref="top_recommend_request"
        link-ref-kind="home"
        class="c-homeMainContent_recommendRequest"
        is-nuxt-link-headline
      />
    </template>

    <EzTopRegisterForFree
      v-if="!isLoggedIn"
      class="c-homeMainContent_register isSP"
      ref-value="sp_top_main_register_2"
      type="primary"
      data-translate
    />

    <ContentHeadline
      class="c-categoriesRankingTitle"
      title-tag="h2"
      title-text="カテゴリ別ランキング"
      :sub-title-text="cateogryRankingTerm"
    />

    <div
      class="c-homeMainContent_carouselServiceArea"
      :class="{ 'c-homeMainContent_carouselServiceArea-noPrivate': privateAccountInfo.isMzhc }"
    >
      <CarouselService
        v-for="(category, index) in categoryRankingList"
        :key="`category${index}`"
        :title="
          generateCateogryRankingTitle(
            category.masterCategoryTitle,
            category.masterCategoryId,
            category.serviceClass
          )
        "
        title-tag="h3"
        :title-icon="`category-${category.masterCategoryRomaji}`"
        :title-url="urlTitle(category)"
        title-url-more="もっと見る"
        :url-more="urlMore(category)"
        :services="category.servicesList"
        link-ref="top_ranking"
        link-ref-kind="home"
        :link-ref-category="category.masterCategoryId"
        is-ranking
        is-nuxt-link-headline
        class="c-homeMainContent_categoryRankingService"
        :class="!index ? 'c-homeMainContent_categoryRankingService-firstItem' : ''"
      />
    </div>

    <CarouselLegal
      v-if="
        homeContent.legalQasList.length &&
        $translate.showJapaneseOnly() &&
        !privateAccountInfo.isMzhc
      "
      title="法律Q&amp;A"
      title-tag="h3"
      title-url="/bbses/ranking?cn_device=pc&amp;cn_clicked=ranking_02"
      title-url-more="もっと見る"
      url-more="/bbses/ranking?cn_device=pc&amp;cn_clicked=ranking_02"
      :legal-qas="homeContent.legalQasList"
      is-ranking
      class="c-homeMainContent_legal"
    />

    <EzTopRegisterForFree
      v-if="!isLoggedIn"
      class="c-homeMainContent_register isSP"
      ref-value="sp_top_main_register_3"
      type="primary"
      data-translate
    />

    <ClientOnly>
      <DTranslatedByGoogle v-if="$translate.isTranslatableTarget()" />
    </ClientOnly>

    <CoconalaMerit
      v-if="isLoggedIn && !privateAccountInfo.isMzhc"
      class="c-homeMainContent_coconalaMerit"
    />
  </div>
</template>

<script>
import CoconalaMerit from './components/CoconalaMerit'
import RecommendCategories from './components/RecommendCategories'
import CarouselService from './components/CarouselService'
import CarouselUser from './components/CarouselUser'
import CarouselBlog from './components/CarouselBlog'
import CarouselRequest from './components/CarouselRequest'
import CarouselLegal from './components/CarouselLegal'
import CarouselRecommendRequests from './components/CarouselRecommendRequests'
import ContentHeadline from '~/components/molecules/ContentHeadline'
import PopularUseScenes from './components/PopularUseScenes'
import BeginnerBanner from '~/components/molecules/BeginnerBanners'
import { CategoryRankingDefaultSort } from '~/stub/domain/enumeration/category_ranking_default_sort_pb'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HomeMainContent',
  components: {
    CarouselRecommendRequests,
    CoconalaMerit,
    RecommendCategories,
    CarouselService,
    CarouselUser,
    CarouselBlog,
    CarouselRequest,
    CarouselLegal,
    ContentHeadline,
    PopularUseScenes,
    BeginnerBanner,
    EzTopRegisterForFree: () => import('~/components/organisms/ez-top/EzTopRegisterForFree'),
    DTranslatedByGoogle: () => import('~/components/molecules/DS2/DTranslatedByGoogle')
  },
  props: {
    homeContent: {
      type: Object,
      required: true
    },
    myServiceHistories: {
      type: Array,
      default: () => []
    },
    myFavoriteServices: {
      type: Array,
      default: () => []
    },
    myRecommendRequests: {
      type: Object,
      default: () => ({
        algorithm: '',
        impressionParamsJson: '',
        recommendRequestsList: [],
        currentDate: {
          nanos: undefined,
          seconds: undefined
        }
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('my/header', ['privateAccountInfo', 'headerType']),
    ...mapState('constants', ['serviceClass', 'VIRTUAL_CATEGORY']),
    ...mapState('constants', ['HEADER_TYPE']),
    cateogryRankingTerm() {
      if (!this.homeContent || !this.homeContent.categoryRanking) {
        return ''
      }
      if (
        this.homeContent.categoryRanking.rankingTermStart &&
        this.homeContent.categoryRanking.rankingTermEnd
      ) {
        const start = this.$util.dayjs
          .unix(this.homeContent.categoryRanking.rankingTermStart)
          .format('M/D')
        const end = this.$util.dayjs
          .unix(this.homeContent.categoryRanking.rankingTermEnd)
          .format('M/D')
        return `期間：${start}〜${end}`
      }
      return ''
    },
    showBeginnerBanner() {
      return !this.isLoggedIn && this.$translate.showJapaneseOnly()
    },
    categoryRankingList() {
      if (this.privateAccountInfo.isMzhc) {
        return this.homeContent.categoryRanking.detailsList.filter(detail => detail.isBusiness)
      }
      return this.homeContent.categoryRanking.detailsList
    },
    isDisplayProvider() {
      return this.headerType === this.HEADER_TYPE.PROVIDER
    }
  },
  methods: {
    generateCateogryRankingTitle(title, id, serviceClass) {
      if (!serviceClass) {
        return title
      }

      // 占いカテゴリ且つ電話サービスの判定
      if (
        id === this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.CATEGORY_ID &&
        serviceClass === this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.SERVICE_CLASS
      ) {
        return this.VIRTUAL_CATEGORY.DIVINATION_PHONE.NAME
      }

      return `${title}の電話相談`
    },
    serviceKind(masterCategoryId, serviceClass) {
      // 占い以外のカテゴリの時は何も返さない
      if (masterCategoryId !== 3) {
        return ''
      }

      if (serviceClass === 1) {
        // 電話占いの時(電話サービス)
        return '&service_kind=1'
      }

      // 占いの時(通常サービス)
      return '&service_kind=0'
    },
    urlTitle(category) {
      return (
        `/categories/${category.masterCategoryId}?ref=top_ranking&sort_by=` +
        this.sortBy(category.defaultSort) +
        this.serviceKind(category.masterCategoryId, category.serviceClass)
      )
    },
    sortBy(defaultSort) {
      let sortBy = ''
      switch (defaultSort) {
        case CategoryRankingDefaultSort.CATEGORY_RANKING_DEFAULT_SORT_UNSPECIFIED:
          sortBy = 'ranking' //未指定の場合従来の動作に合わせてrankingにする
          break
        case CategoryRankingDefaultSort.CATEGORY_RANKING_DEFAULT_SORT_RECOMMEND:
          sortBy = 'recommend' //おすすめ順
          break
        case CategoryRankingDefaultSort.CATEGORY_RANKING_DEFAULT_SORT_NEW:
          sortBy = 'new' //新着順
          break
        case CategoryRankingDefaultSort.CATEGORY_RANKING_DEFAULT_SORT_RANKING:
          sortBy = 'ranking' //ランキング順
          break
        case CategoryRankingDefaultSort.CATEGORY_RANKING_DEFAULT_SORT_FAVORITE:
          sortBy = 'fav' //お気に入り順
          break
        case CategoryRankingDefaultSort.CATEGORY_RANKING_DEFAULT_SORT_BEGINNER:
          sortBy = 'beginner' //初心者向け
          break
        default:
          sortBy = 'ranking'
          break
      }
      return sortBy
    },
    urlMore(category) {
      const categoryTopQuery = this.isLoggedIn ? '&category_top=false' : ''
      return (
        `/categories/${category.masterCategoryId}?sort_by=` +
        this.sortBy(category.defaultSort) +
        this.serviceKind(category.masterCategoryId, category.serviceClass) +
        '&ref=top_ranking' +
        categoryTopQuery
      )
    }
  }
}
</script>

<style lang="scss" scoped>
// サービス詳細に進んでから戻ったときにスクロール位置を保持するための、カテゴリ別ランキングのmin-heightの設定に必要な値
$category_count: 19; // カテゴリ別ランキングのカテゴリ数
$category_count_mzhc: 11; // カテゴリ別ランキングのカテゴリ数（みずココ）
$carousel_height_pc: 245.59px; // 1カテゴリの高さ（PC）
$carousel_margin_pc: 28px; // カテゴリ間のマージン（PC）
$carousel_height_sp: 225.02px; // 1カテゴリの高さ（SP）
$carousel_margin_sp: 20px; // カテゴリ間のマージン（SP）

.c-homeMainContent {
  &_favoriteService {
    &-hasRecommendRequests {
      margin-top: 40px;
    }
  }

  &_serviceHistory {
    margin-top: 40px;
  }

  &_recommendCategories {
    margin-top: 40px;
  }

  &_useScenes {
    margin-top: 40px;
  }

  &_coconalaMerit {
    margin-top: 45px;
  }

  &_recommendService {
    margin-top: 40px;
  }

  &_recommendUser {
    margin-top: 40px;
  }

  &_recommendBlog {
    margin-top: 40px;
  }

  &_recommendRequest {
    margin-top: 40px;
  }

  &_legal {
    margin-top: 28px;
    min-height: 220px;
  }

  &_carouselServiceArea {
    $carousel_height_pc_sum: $carousel_height_pc * $category_count;
    $carousel_margin_pc_sum: $carousel_margin_pc * ($category_count - 1);

    min-height: $carousel_height_pc_sum + $carousel_margin_pc_sum;

    &-noPrivate {
      $carousel_height_pc_sum_mzhc: $carousel_height_pc * $category_count_mzhc;
      $carousel_margin_pc_sum_mzhc: $carousel_margin_pc * ($category_count_mzhc - 1);

      min-height: $carousel_height_pc_sum_mzhc + $carousel_margin_pc_sum_mzhc;
    }
  }

  &_categoryRankingService {
    margin-top: 28px;

    &-firstItem {
      margin-top: 16px;
    }
  }
}

.c-categoriesRankingTitle {
  margin-top: 40px;
}

@media (min-width: breakpoint(Home, M) + 1) {
  .c-homeMainContent {
    &_coconalaMerit {
      margin-top: 40px;
    }
  }

  .isSP {
    display: none;
  }
}

@media (max-width: breakpoint(Home, M)) {
  .c-homeMainContent {
    &_recommendCategories {
      &-mzhc {
        margin-top: 40px;
      }
    }
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-homeMainContent {
    &_serviceHistory {
      margin-top: 40px;
    }
    &_favoriteService {
      margin-top: 40px;
    }
    &_banners {
      margin-top: 32px;
      padding: 0 12px;
    }

    &_legal {
      margin-top: 20px;
      min-height: 200px;
    }

    &_carouselServiceArea {
      $carousel_height_sp_sum: $carousel_height_sp * $category_count;
      $carousel_margin_sp_sum: $carousel_margin_sp * ($category_count - 1);

      min-height: $carousel_height_sp_sum + $carousel_margin_sp_sum;

      &-noPrivate {
        $carousel_height_sp_sum_mzhc: $carousel_height_sp * $category_count_mzhc;
        $carousel_margin_sp_sum_mzhc: $carousel_margin_sp * ($category_count_mzhc - 1);

        min-height: $carousel_height_sp_sum_mzhc + $carousel_margin_sp_sum_mzhc;
      }
    }

    &_categoryRankingService {
      margin-top: 20px;

      &-firstItem {
        margin-top: 8px;
      }
    }

    &_register {
      margin-top: 40px;
    }
  }

  .c-categoriesRankingTitle {
    padding: 0 12px;
  }
}
</style>
