var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-homeHeroAreaSwiper"},[(_vm.isShowFixedBanner())?_c('div',{staticClass:"c-homeHeroAreaSwiper_imageWrapper"},[(!_vm.isLoggedIn)?_c('NuxtLink',{attrs:{"to":"/business/?ref=pc_hero_business"}},[_c('img',{attrs:{"src":_vm.$coconala.fileURL('/staticfiles/images/pages/business/hero_banner_non_login.png'),"alt":"coconala Business","loading":"lazy","decoding":"async"}})]):(_vm.isBusiness)?_c('a',{attrs:{"href":_vm.$coconala.coconalaJobMatchingUrl('/client', { ref: 'fixed_hero_enterprise' })}},[_c('img',{attrs:{"src":_vm.$coconala.fileURL(
            '/staticfiles/images/pages/business/hero_banner_login_job_matching.png'
          ),"alt":"ココナラ募集","loading":"lazy","decoding":"async"}})]):_c('NuxtLink',{attrs:{"to":"/business/introduction"}},[_c('img',{attrs:{"src":_vm.$coconala.fileURL('/staticfiles/images/pages/business/hero_banner_login.png'),"alt":"coconala Business","loading":"lazy","decoding":"async"}})])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"c-homeHeroAreaSwiper_swiper",class:{ 'c-homeHeroAreaSwiper_swiperFull': !_vm.isShowFixedBanner() }},[_c('div',{ref:"swiper",staticClass:"c-homeHeroAreaSwiper_swiperWrapper",class:{ 'c-homeHeroAreaSwiper_swiperWrapperFull': !_vm.isShowFixedBanner() }},[_c('div',{staticClass:"c-homeHeroAreaSwiper_wrapper swiper-wrapper",class:{
          'c-homeHeroAreaSwiper_wrapper-beforeInit': !_vm.isReady && _vm.isShowFixedBanner(),
          'c-homeHeroAreaSwiper_wrapper-beforeInitFull': !_vm.isReady && !_vm.isShowFixedBanner(),
          'c-homeHeroAreaSwiper_wrapper-threeImageView': !_vm.isShowFixedBanner()
        }},_vm._l((_vm.filteredHeroAreasList),function(heroAreaItem,idx){return _c('a',{key:`heroArea${idx}`,staticClass:"c-homeHeroAreaSwiper_slide swiper-slide",class:{
            'c-homeHeroAreaSwiper_slide-final': _vm.isFinalSlide(idx),
            'c-homeHeroAreaSwiper_slide-hideBeforeInit': heroAreaItem.businessSPbanner,
            'c-homeHeroAreaSwiper_slide-threeImageView': !_vm.isShowFixedBanner()
          },attrs:{"href":heroAreaItem.url}},[_c('img',_vm._b({staticClass:"c-homeHeroAreaSwiper_slideImage",attrs:{"src":heroAreaItem.image,"alt":heroAreaItem.title}},'img',idx <= 1 ? { fetchpriority: 'high' } : { loading: 'lazy', decoding: 'async' },false))])}),0),_vm._v(" "),_c('div',{staticClass:"c-homeHeroAreaSwiper_swiperPagination"})]),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReady),expression:"isReady"}],staticClass:"c-homeHeroAreaSwiper_swiperButton c-homeHeroAreaSwiper_swiperButton-prev"},[_c('CoconalaIcon',{staticClass:"c-homeHeroAreaSwiper_swiperButtonIcon",attrs:{"name":"chevron-left","size":"12px","color":"gray800"}})],1),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReady),expression:"isReady"}],staticClass:"c-homeHeroAreaSwiper_swiperButton c-homeHeroAreaSwiper_swiperButton-next"},[_c('CoconalaIcon',{staticClass:"c-homeHeroAreaSwiper_swiperButtonIcon",attrs:{"name":"chevron-right","size":"12px","color":"gray800"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }