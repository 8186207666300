<template>
  <div class="c-homeHeroAreaSwiper">
    <div
      v-if="isShowFixedBanner()"
      class="c-homeHeroAreaSwiper_imageWrapper"
    >
      <NuxtLink
        v-if="!isLoggedIn"
        to="/business/?ref=pc_hero_business"
      >
        <img
          :src="$coconala.fileURL('/staticfiles/images/pages/business/hero_banner_non_login.png')"
          alt="coconala Business"
          loading="lazy"
          decoding="async"
        />
      </NuxtLink>
      <a
        v-else-if="isBusiness"
        :href="$coconala.coconalaJobMatchingUrl('/client', { ref: 'fixed_hero_enterprise' })"
      >
        <img
          :src="
            $coconala.fileURL(
              '/staticfiles/images/pages/business/hero_banner_login_job_matching.png'
            )
          "
          alt="ココナラ募集"
          loading="lazy"
          decoding="async"
        />
      </a>
      <!-- 下記のv-elseには旧EFユーザーしか来ない想定 -->
      <NuxtLink
        v-else
        to="/business/introduction"
      >
        <img
          :src="$coconala.fileURL('/staticfiles/images/pages/business/hero_banner_login.png')"
          alt="coconala Business"
          loading="lazy"
          decoding="async"
        />
      </NuxtLink>
    </div>
    <div
      class="c-homeHeroAreaSwiper_swiper"
      :class="{ 'c-homeHeroAreaSwiper_swiperFull': !isShowFixedBanner() }"
    >
      <div
        class="c-homeHeroAreaSwiper_swiperWrapper"
        :class="{ 'c-homeHeroAreaSwiper_swiperWrapperFull': !isShowFixedBanner() }"
        ref="swiper"
      >
        <div
          class="c-homeHeroAreaSwiper_wrapper swiper-wrapper"
          :class="{
            'c-homeHeroAreaSwiper_wrapper-beforeInit': !isReady && isShowFixedBanner(),
            'c-homeHeroAreaSwiper_wrapper-beforeInitFull': !isReady && !isShowFixedBanner(),
            'c-homeHeroAreaSwiper_wrapper-threeImageView': !isShowFixedBanner()
          }"
        >
          <a
            v-for="(heroAreaItem, idx) in filteredHeroAreasList"
            :key="`heroArea${idx}`"
            class="c-homeHeroAreaSwiper_slide swiper-slide"
            :class="{
              'c-homeHeroAreaSwiper_slide-final': isFinalSlide(idx),
              'c-homeHeroAreaSwiper_slide-hideBeforeInit': heroAreaItem.businessSPbanner,
              'c-homeHeroAreaSwiper_slide-threeImageView': !isShowFixedBanner()
            }"
            :href="heroAreaItem.url"
          >
            <img
              class="c-homeHeroAreaSwiper_slideImage"
              :src="heroAreaItem.image"
              :alt="heroAreaItem.title"
              v-bind="idx <= 1 ? { fetchpriority: 'high' } : { loading: 'lazy', decoding: 'async' }"
            />
          </a>
        </div>
        <div class="c-homeHeroAreaSwiper_swiperPagination"></div>
      </div>
      <button
        class="c-homeHeroAreaSwiper_swiperButton c-homeHeroAreaSwiper_swiperButton-prev"
        v-show="isReady"
      >
        <CoconalaIcon
          class="c-homeHeroAreaSwiper_swiperButtonIcon"
          name="chevron-left"
          size="12px"
          color="gray800"
        />
      </button>
      <button
        class="c-homeHeroAreaSwiper_swiperButton c-homeHeroAreaSwiper_swiperButton-next"
        v-show="isReady"
      >
        <CoconalaIcon
          class="c-homeHeroAreaSwiper_swiperButtonIcon"
          name="chevron-right"
          size="12px"
          color="gray800"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Swiper from 'swiper/dist/js/swiper.esm.bundle'

/** @type {import('vue').ComponentOptions} */
const homeHeroAreaSwiper = {
  name: 'HomeHeroAreaSwiper',
  props: {
    heroAreasList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isReady: false,
      swiper: null
    }
  },
  watch: {
    filteredHeroAreasList(newValue, oldValue) {
      this.$nextTick(() => {
        if (newValue.length !== oldValue.length) {
          this.swiper?.destroy()
          this.swiper = new Swiper(this.$refs.swiper, this.getSwiperOptions())
        }
      })
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC', 'isSP']),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('auth/user', ['isBusiness', 'isShowBusinessUseBanner']),
    ...mapState('my/header', ['headerType']),
    ...mapState('constants', ['HEADER_TYPE']),
    resizedImageUrlPrefix() {
      const baseUrl = process.env.config['akamaiUrl']['heroArea']
      return `${baseUrl}/resize/840/472`
    },
    placeholderList() {
      if (this.heroAreasList.length === 0) {
        return []
      }
      const listLength = this.filteredHeroAreasList.length
      return [
        this.filteredHeroAreasList[listLength - 1],
        this.filteredHeroAreasList[0],
        this.filteredHeroAreasList[listLength > 1 ? 1 : 0]
      ]
    },
    filteredHeroAreasList() {
      const filteredlist = this.heroAreasList.map(banner => {
        const copy = { ...banner }
        copy.image = this.getResizedImageUrl(copy.image)
        return copy
      })
      if (this.isPC) {
        return filteredlist
      }

      // 受注または個人ユーザーかつ新EFユーザーは法人バナーを非表示
      if (
        (!this.$translate.isTranslatableTarget() &&
          !this.isBusiness &&
          !this.isShowBusinessUseBanner) ||
        this.headerType === this.HEADER_TYPE.PROVIDER
      ) {
        return filteredlist
      }

      const fixedBanner = this.isBusiness
        ? {
            title: 'ココナラ募集',
            image: this.$coconala.fileURL(
              '/staticfiles/images/pages/business/hero_banner_login_job_matching_sp.png'
            ),
            url: this.$coconala.coconalaJobMatchingUrl('/client', { ref: 'fixed_hero_enterprise' }),
            businessSPbanner: true
          }
        : {
            title: 'coconala Business',
            image: this.$coconala.fileURL(
              '/staticfiles/images/pages/business/hero_banner_login_sp.png'
            ),
            url: '/business/introduction',
            businessSPbanner: true
          }

      const businessBannerSp = [
        this.$translate.isTranslatableTarget()
          ? {
              title: '',
              image: require('~/assets/images/common/banner/multilingual_hero_banner.png'),
              url: '/categories/9?ref=sp_hero_business',
              businessSPbanner: true
            }
          : fixedBanner
      ]
      // NOTE: くじ期間中は1番目をくじにしてビジネスを2番目にする。くじ期間終了後に元に戻す必要があるため、元の戻り値をコメントアウトしておく。
      // return businessBannerSp.concat(filteredlist)
      // NOTE: filteredlistの2番目にbusinessBannerSpを差し込む
      // FIXME: 暫定的に?をつけることで対応はしているが、ライフサイクルを見直して、CSR遷移時の挙動を見直す
      if (filteredlist[0]?.url?.match(/\/campaign\/lottery/)) {
        const lotteryBanner = [filteredlist[0]]
        return lotteryBanner.concat(businessBannerSp).concat(filteredlist.slice(1))
      }
      return businessBannerSp.concat(filteredlist)
    }
  },
  methods: {
    isShowFixedBanner() {
      if (this.$translate.isTranslatableTarget()) return false
      // 未ログインユーザー、発注モードの法人または旧EFユーザーに固定バナーを表示
      if (!this.isLoggedIn) return true
      if (
        this.headerType === this.HEADER_TYPE.BUYER &&
        (this.isBusiness || this.isShowBusinessUseBanner)
      ) {
        return true
      }
      return false
    },
    getSwiperOptions() {
      return {
        loop: true,
        slidesPerView: 'auto',
        loopedSlides: this.heroAreasList.length - 1,
        autoplay: true,
        pagination: {
          el: '.c-homeHeroAreaSwiper_swiperPagination'
        },
        navigation: {
          nextEl: '.c-homeHeroAreaSwiper_swiperButton-next',
          prevEl: '.c-homeHeroAreaSwiper_swiperButton-prev',
          disabledClass: 'c-swiper-button-disabled'
        },
        centeredSlides: false,
        on: {
          init: () => {
            this.isReady = true
          }
        },
        breakpoints: {
          959: {
            centeredSlides: true,
            loopedSlides: this.heroAreasList.length
          }
        }
      }
    },
    /**
     * リサイズされた画像URLを取得
     * @param {string} imagePath APIから返却された画像URLの相対パス
     * @returns {string} 画像URL
     */
    getResizedImageUrl(imagePath) {
      return this.resizedImageUrlPrefix + imagePath
    },
    isFinalSlide(slideIndex) {
      if (this.isPC) {
        return slideIndex === this.heroAreasList.length - 1
      }
      return slideIndex === this.heroAreasList.length
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.swiper, this.getSwiperOptions())
    })
  },
  beforeDestroy() {
    // swiper初期化前に遷移するとエラーになるのでOptional Chainingにする
    this.swiper?.destroy()
  }
}
export default homeHeroAreaSwiper
</script>

<style lang="scss" scoped>
@use 'sass:math';

$slide-width: var(--width);
$slide-margin: var(--slide-margin);
$slide-width-sp: 312px;

.c-homeHeroAreaSwiper {
  overflow: hidden;
  position: relative;
  height: 260px;
  display: flex;
  justify-content: space-between;
  &_imageWrapper {
    @include link-effect;
    width: 336px;
    height: 236px;
    padding-right: 24px;
    border-right: 1px solid $ds2-color-gray-50;
    box-sizing: content-box;
    &-multilingual {
      height: auto;
      margin: auto 0;
    }
  }
  &_swiper {
    width: 856px;
    height: 100%;
    overflow: visible;
    position: relative;
  }
  &_swiperFull {
    width: 100%;
  }
  &_swiperWrapper {
    width: 856px;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 24px;
  }
  &_swiperWrapperFull {
    width: 100%;
  }
  &_wrapper {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;

    --width: 420px;
    --slide-margin: 16px;

    &-threeImageView {
      --width: 404px;
      --slide-margin: 14px;
    }
    &-beforeInit {
      // ファーストビューでのスライド位置調整 $slide-width * x + (x-1)*20px
      margin-left: calc(50% - #{$slide-width} * 2 - 20px);
      // Swiper初期化前は最後のスライドを最初に持ってくる
      .c-homeHeroAreaSwiper_slide-final {
        order: -1;
      }
      .c-homeHeroAreaSwiper_slide-hideBeforeInit {
        display: none;
      }
    }
    &-beforeInitFull {
      margin-left: -calc(#{$slide-width} + #{$slide-margin});
      // Swiper初期化前は最後のスライドを最初に持ってくる
      .c-homeHeroAreaSwiper_slide-final {
        order: -1;
      }
      .c-homeHeroAreaSwiper_slide-hideBeforeInit {
        display: none;
      }
    }
  }
  &_slide {
    @include link-effect;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width);
    height: 236px;
    margin-right: var(--slide-margin);

    &-threeImageView {
      width: var(--width);
      height: 227px;
      margin-right: var(--slide-margin);
    }
  }
  &_slideImage {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    background: $ds2-color-gray-25;
  }
  &_swiperPagination {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    ::v-deep .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      margin: 0 2px;
      background-color: $ds2-color-gray-100;
      opacity: 1;
      &-active {
        background-color: $ds2-color-gray-600;
      }
    }
  }
  &_swiperButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: $color-white;
    border: 1px solid $ds2-color-gray-100;
    border-radius: 2px;
    padding: 0;
    z-index: 15;
    cursor: pointer;
    margin-top: -12px;
    &-prev {
      left: -12px;
    }
    &-next {
      right: -12px;
    }
  }
  &_swiperButtonIcon {
    height: 12px;
  }
}
@media (max-width: breakpoint(Home, M)) {
  .c-homeHeroAreaSwiper {
    height: 175px;
    &_imageWrapper {
      display: none;
    }
    &_swiper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }
    &_swiperWrapper {
      width: 100%;
      padding-bottom: 0;
      margin-left: 0;
    }
    &_wrapper {
      &-beforeInit {
        // ファーストビューでのスライド位置調整
        margin-left: calc(50% - #{math.div($slide-width-sp * 3, 2) + 12px});
        .c-homeHeroAreaSwiper_slide-hideBeforeInit {
          display: block;
        }
      }
    }
    &_slide {
      width: $slide-width-sp;
      height: 175px;
      margin: 0 4px;
    }
    &_swiperPagination {
      z-index: 1;
      bottom: 8px;
      ::v-deep .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 6px;
      }
    }
    &_swiperButton {
      display: none;
    }
  }
}
</style>
