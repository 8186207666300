import { ref, computed, useContext } from '@nuxtjs/composition-api'
import { SuggestItem } from '~/components/molecules/DS2/DSuggest/types'
import { Corporate } from '~/stub/profileservice/master_pb'
import { debounce } from 'lodash-es'

const DELAY_TIME_SEARCH_CORPORATES = 500
const LIMIT_CORPORATES_COUNT = 10

// dispatchを使用するため、Storeの型でdispatchだけを必須に指定している
export const useSearchCorporates = () => {
  const masterCorporatesList = ref<Corporate.AsObject[]>([])
  const { $util, store } = useContext()

  // computedをそのまま使うとuseFetchとの不具合でwarnがでるため、get/setで記載することで回避している
  const corporatesSuggestList = computed<SuggestItem[]>({
    get: () => {
      return masterCorporatesList.value.map(item => {
        return {
          name: $util.toHalfWidth(item.name),
          description: $util.toHalfWidth(item.address)
        }
      })
    },
    set: () => {}
  })
  const selectCorporateName = (setValue: { corporateName: string }, selectItem: string) => {
    setValue.corporateName = selectItem
  }
  const onInput = (inputVal: string, maxNameLength: number | null = null) => {
    masterCorporatesList.value = []
    searchCorporates(inputVal, maxNameLength)
  }

  const searchCorporates = debounce(async (text: string, maxNameLength: number | null = null) => {
    try {
      const res = await store.dispatch('master/searchCorporates', {
        searchWord: text,
        limit: LIMIT_CORPORATES_COUNT,
        maxNameLength
      })
      masterCorporatesList.value = res.corporatesList
    } catch (err) {
      // 自由入力できるので握りつぶしてもOK
    }
  }, DELAY_TIME_SEARCH_CORPORATES)

  return {
    corporatesSuggestList,
    selectCorporateName,
    onInput
  }
}
